.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}

.form-control-select.is-invalid ~ .invalid-feedback {
  display: block;
}
